
const DEFAULT_SELECTOR: string = 'img[data-img-on-click]';

export default function initClickableImages(document: Document, selector?: string): void {
	selector = selector || DEFAULT_SELECTOR;
	document.querySelectorAll(selector).forEach((element: HTMLImageElement) => {
		processElement(element, document);
	});
}

function processElement(element: HTMLImageElement, document: Document): void {
	const selector: string = element.getAttribute('data-img-on-click');
	if (!selector) {
		return;
	}
	const imageOnClick: HTMLImageElement = document.querySelector(selector);
	if (!imageOnClick) {
		return;
	}
	const src: string = element.src;
	const srcOnClick: string = imageOnClick.src;
	let currentSrc = src;
	element.addEventListener('click', () => {
		if (currentSrc === src) {
			element.src = currentSrc = srcOnClick;
		} else {
			element.src = currentSrc = src;
		}
	});
}
