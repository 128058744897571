import $ from 'jquery';
import 'slick-carousel/slick/slick.min';
import { Fancybox, Carousel } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox.css';

/**
 *
 * @param {Document} document
 * @param {Window} window
 */
export default function jQueryRun(document, window) {

	// $('body').scrollspy({ target: '#navbar' });

	$('.slider').slick({
		dots: true,
		arrows: false,
		fade: true,
	});

	/* $('.text-slider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: false,
		infinite: false,
		arrows: false
	});

	$('.text-slider__nav').slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		asNavFor: '.text-slider',
		dots: false,
		focusOnSelect: true,
		variableWidth: true
	});
	if ($('.text-slider__nav-item') < 5) {
		$('.text-slider__nav').slick({
			arrows: true
		});
	} */

	$('.video__container').slick({
		dots: true,
		arrows: false,
		fade: false,
	});

	// let header = $('.header');
	// let headerSecond = $('.header__second');

	// if (headerSecond) {
	// 	$(window).scroll(function () {
	// 		if ($(this).scrollTop() >= topPos) {
	// 			headerSecond.addClass('fixed');
	// 			header.addClass('top');
	// 		} else {
	// 			headerSecond.removeClass('fixed');
	// 			header.removeClass('top');
	// 		}
	// 	});
	// }

	const $validate = $('#validate');

	function validateEmail(email) {
		// const re =
		// /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const re = /.+@.+\..+/i;
		return re.test(email);
	}

	$('.email__button').attr('disabled', true);

	function validate() {
		const email = $('#email').val().trim();
		const emailForm = $('.email__form');
		if (validateEmail(email)) {
			$validate.css('color', '#1A9F29');
			$validate.addClass('send');
			$('.email__button').attr('disabled', false);
			emailForm.html('Спасибо! Обещаем бережно относиться к вашей почте и не спамить.');
			emailForm.addClass('email__inner');
		} else {
			$validate.css('color', '#1A9F2980');
			$('#email').css('color', 'red');
			$('.email__button').attr('disabled', true);
		}
		return false;
	}

	function onChange() {
		let emailVal = $('#email').val();
		emailVal = $.trim(emailVal);
		$('#email').css('color', 'black');
		if (emailVal.length <= 0) {
			$('.email__button').attr('disabled', true);
			$validate.css('color', '#1A9F2980');
		} else {
			$validate.css('color', '#1A9F29');
			$('.email__button').attr('disabled', false);
		}
	}

	$('.email__input').on('input', onChange);
	$('#validate').on('click', validate);

	/* изменил разметку, это больше не нужно

	$('.footer-col').each(function () {
		if (!$(this).children('.footer__title').length > 0) {
			$(this).css('margin-top', '28px');
		}
	});
	*/

	/* запускаем кнопку вверх при загрузке старницы */
	window.addEventListener('load', function () {
		buttonUp();
	});

	/* запускаем кнопку вверх при изменении размера окна */
	window.addEventListener('resize', function () {
		buttonUp();
	});

	/* Кнопка вверх */

	function buttonUp() {
		const btn = $('.button-up');
		let mediaQuerySize = 768;
		let windowWidth = $(this).innerWidth();
		/* проверяем больше ли размер окна чем нам нужно */
		if (windowWidth >= mediaQuerySize) {
			$(window).scroll(function () {
				if ($(window).scrollTop() > 300) {
					btn.addClass('show');
				} else {
					btn.removeClass('show');
				}
			});

			btn.on('click', function (e) {
				e.preventDefault();
				$('html, body').animate({ scrollTop: 0 }, '300');
			});
		}
	}

	/* Обрезание текста с тремя точками в конце */

	$('.documents__text').each(function () {
		if (window.matchMedia('(max-width: 768px)').matches) {
			if ($(this).prop('innerHTML').length > 40) {
				let val = $(this).prop('innerHTML');
				$(this).prop('innerHTML', val.substr(0, 40) + '...');
			}
		}
		if ($(this).prop('innerHTML').length > 63) {
			let val = $(this).prop('innerHTML');
			$(this).prop('innerHTML', val.substr(0, 63) + '...');
		}
	});

	function handler(event) {
		let eventTarget = event.target.closest('.list__title');
		let target = $(event.target);
		if (target.is('.list__title')) {
			$(eventTarget).toggleClass('rotate');
			target.children().toggle();
		}
	}

	$('.list').click(handler).find('ul').show();

	/* Text Slider */

	const carousel = $('.mainCarousel');

	if (carousel.length > 0) {
		$('.text-block').each(function (index, elem) {
			if ($(elem).find('.mainCarousel').length > 0){
				const mainCarousel = new Carousel(elem.querySelector('.mainCarousel'), {
					Dots: false,
					Navigation: {
						prevTpl: '<svg width="73" height="66" viewBox="0 0 73 66" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="70" height="2" transform="matrix(-1 0 0 1 73 31.5273)" fill=" "/><path fill-rule="evenodd" clip-rule="evenodd" d="M2.31681 31.1127L33.4295 0L34.8438 1.41421L3.73102 32.5269L34.8437 63.6396L33.4295 65.0539L2.31684 33.9411L0.902596 32.527L0.902622 32.5269L2.31681 31.1127Z" fill=""/></svg>',
						nextTpl: '<svg width="73" height="66" viewBox="0 0 73 66" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y="31.5273" width="70" height="2" fill=" "/><path fill-rule="evenodd" clip-rule="evenodd" d="M70.6831 31.1127L39.5704 0L38.1562 1.41422L69.2689 32.5269L38.1562 63.6396L39.5704 65.0539L70.6831 33.9411L72.0973 32.5269L70.6831 31.1127Z" fill=""/></svg>'
					},
				});

				const thumbCarousel = new Carousel(elem.querySelector('.thumbCarousel'), {
					Sync: {
						target: mainCarousel,
						friction: 0,
					},
					Dots: false,
					Navigation: false,
					center: false,
					slidesPerPage: 1,
					infinite: false,
				});

				Fancybox.bind('[data-fancybox="gallery"]', {
					Carousel: {
						Navigation: {
							prevTpl: '<svg width="73" height="66" viewBox="0 0 73 66" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="70" height="2" transform="matrix(-1 0 0 1 73 31.5273)" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M2.31681 31.1127L33.4295 0L34.8438 1.41421L3.73102 32.5269L34.8437 63.6396L33.4295 65.0539L2.31684 33.9411L0.902596 32.527L0.902622 32.5269L2.31681 31.1127Z" fill="white"/></svg>',
							nextTpl: '<svg width="73" height="66" viewBox="0 0 73 66" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y="31.5273" width="70" height="2" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M70.6831 31.1127L39.5704 0L38.1562 1.41422L69.2689 32.5269L38.1562 63.6396L39.5704 65.0539L70.6831 33.9411L72.0973 32.5269L70.6831 31.1127Z" fill="white"/></svg>'
						},
					},
					Toolbar: {
						display: [
							'close'
						],
					},
					click: false,
					Image: {
						zoom: false,
					},
				});
			}
		});
	}
}

