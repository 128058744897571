/**
 * Модуль для обновления тегов со временем на странице.
 *
 * @author MaximAL
 * @copyright © MaximAL 2020
 */

import DateTime from 'luxon/src/datetime';
import Settings from 'luxon/src/settings';

/**
 * Интервал обновления тегов со временем
 * @type {number}
 */
const UPDATE_INTERVAL = 10 * 1000;

/**
 * Селектор по умолчанию для тегов со временем
 * @type {string}
 */
const DEFAULT_SELECTOR = 'time[data-relative=true]';

/**
 * Формат даты и времени.
 * MOMENT — для Moment.js
 * LUXON — для Luxon
 */
const DATETIME_FORMAT = {
	MOMENT: 'LLLL',
	LUXON: {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		hour: 'numeric',
		minute: '2-digit',
		weekday: 'long',
		timeZoneName: 'short',
	},
};

/**
 * Инициализировать дефолтное поведение для тегов со временем.
 * Используется селектор `DEFAULT_SELECTOR`: `<time
 * data-relative="true"></time>`.
 *
 * Время в тегах обновляется раз в `UPDATE_INTERVAL` миллисекунд (по умолчанию:
 * 10 секунд).
 * ```
 * // Например:
 * initDefault();
 * ```
 */
export function initDefault(document) {
	//moment.locale(navigator.language);
	Settings.defaultLocale = navigator.language || 'en';

	document.querySelectorAll(DEFAULT_SELECTOR).forEach((element) => {
		processElement(element);
	});

	setInterval(() => {
		document.querySelectorAll(DEFAULT_SELECTOR).forEach((element) => {
			processElement(element);
		});
	}, UPDATE_INTERVAL);
}

/**
 * Инициализировать дефолтное поведение для заданных тегов со временем.
 * ```
 * // Например:
 * init(document.querySelectorAll('time'));
 * ```
 * @param {NodeListOf<HTMLElement>} elements
 */
export function init(elements) {
	//moment.locale(navigator.language);
	Settings.defaultLocale = navigator.language || 'en';

	elements.forEach((element) => processElement(element));
	setInterval(() => {
		elements.forEach((element) => processElement(element));
	}, UPDATE_INTERVAL);
}

/**
 * Обработать единожды заданный тег со временем.
 * ```
 * // Например:
 * processElement(document.querySelector('time'));
 * ```
 * @param {HTMLElement} element
 */
export function processElement(element) {
	const noRelative = element.getAttribute('data-relative');
	if (noRelative === 'false') {
		return;
	}
	const value = element.getAttribute('datetime');
	if (value) {
		// Moment.js
		//const time = moment(value);
		//element.setAttribute('title', time.format(DATETIME_FORMAT.MOMENT));
		//element.innerText = time.fromNow();
		// Luxon
		// Если дата передана не в ISO, пробуем сконвертировать:
		// заменяем пробел на T и подставляем в конец Z (UTC)
		const time = DateTime.fromISO(
			value.replace(' ', 'T').replace(/(T\d\d:\d\d:\d\d)$/i, '$1Z'),
		);
		element.setAttribute('title', time.toLocaleString(DATETIME_FORMAT.LUXON));
		if (Math.abs((DateTime.local() - time) / 1000) < 10) {
			element.innerText = 'только что';
		} else {
			element.innerText = time.toRelative();
		}
	}
}

/**
 *
 * @param {string|Date} value
 * @returns {string}
 */
export function dateTimeToRelative(value) {
	let time;
	if (value instanceof Date) {
		time = DateTime.fromJSDate(value);
	} else {
		// Если дата передана не в ISO, пробуем сконвертировать:
		// заменяем пробел на T и подставляем в конец Z (UTC)
		time = DateTime.fromISO(
			value.replace(' ', 'T').replace(/(T\d\d:\d\d:\d\d)$/i, '$1Z'),
		);
	}
	if (Math.abs((DateTime.local() - time) / 1000) < 10) {
		return 'только что';
	}
	return time.toRelative();
}

/**
 * @param {Date} date
 * @returns {string}
 */
export function getISODate(date) {
	return DateTime.fromJSDate(date).toISODate();
}

/**
 * @param {Date|String} date
 * @returns {string}
 */
export function getLocaleDate(date) {
	const dt = date instanceof Date
		? DateTime.fromJSDate(date)
		: DateTime.fromISO(date.replace(' ', 'T'));
	return dt.toLocaleString(DateTime.DATETIME_SHORT);
}
