import 'popper.js/dist/popper';
import 'bootstrap/js/src/util';
import 'bootstrap/js/src/modal';
import 'bootstrap/js/src/tooltip';
import 'bootstrap/js/src/popover';
import { initDefault } from './modules/relative-time';
import detectAvifWebp from './modules/avif-webp-detect';
import initAnchorLinks from './modules/anchor-links';
import initScrollSpy from './modules/scroll-spy';
import textFit from 'textfit';
import initPopovers from './modules/popover';
import initClickableImages from './modules/clickable-images';

/**
 *
 * @param {Document} document
 * @param {Window} window
 */
export default function appRun(document, window) {
	// Убираем маркер безъяваскриптовости
	document.body.classList.remove('no-js');

	// Относительное время на страницах
	initDefault(document);
	// Прилипание второго хедера
	stickyHeader();

	//отслеживание ширины экрана
	window.addEventListener('load', function () {
		let mediaQuerySize = 768;
		// Берём текущую ширину экрана
		let windowWidth = window.innerWidth;

		// Если ширина экрана меньше mediaQuerySize(768)
		if (windowWidth < mediaQuerySize) {
			// Синхронизация скролла тела таблицы и её фиксированного заголовка
			syncTableScroll();

			// Грузим скрипт после загрузки страницы
			if (document.readyState !== 'loading') {
				fixHeadTable();
			}
			// modern browsers
			else if (document.addEventListener) {
				document.addEventListener('DOMContentLoaded', fixHeadTable);
			}
			// IE <= 8
			else {
				document.attachEvent('onreadystatechange', function () {
					if (document.readyState === 'complete') {
						fixHeadTable();
					}
				});
			}
		}
	});

	// Определение поддержки современных форматов изображений
	detectAvifWebp(document);

	// Инициализируем якорные ссылки с плавной прокруткой
	initAnchorLinks(document, window);
	initAnchorLinks(document, window, 'li.list__item a[data-anchor^="#"]');
	initScrollSpy('section[id]', 'li.list__item a[data-anchor^="#"]');

	// Инициализируем кликабельные картинки
	initClickableImages(document);

	// Анимации
	animateDrone('#flying-drone');
	animateTrafficLights();

	// Поповеры
	initPopovers('[data-popover-target]');

	// Баннер на главной
	initBruceBanner();

	initToggleNav();
	initVideoButtons();

	init404Button();
	init404Clock();

	// Укладываем текст
	textFit(document.getElementsByClassName('js-text-fit'), { minFontSize: 50, maxFontSize: 100 });

	initHeroScroll();

	// Фиксация навигации по странице .header-second
	function stickyHeader() {
		const headerSecond = document.querySelector('.header__second');
		let placeholder = document.createElement('div');
		if (!headerSecond) {
			return;
		}

		let menuPosition = headerSecond.getBoundingClientRect();

		placeholder.style.width = menuPosition.width + 'px';
		placeholder.style.height = menuPosition.height + 'px';
		let isAdded = false;

		let el = headerSecond;
		let offsetTop = 0;

		while (el !== document.body) {
			offsetTop += el.offsetTop;
			el = el.offsetParent;
		}

		let headPosition = offsetTop;

		window.addEventListener('scroll', function () {
			if (window.scrollY >= headPosition && !isAdded) {
				headerSecond.classList.add('sticky');
				headerSecond.parentNode.insertBefore(placeholder, headerSecond);
				isAdded = true;
			} else if (window.scrollY < headPosition && isAdded) {
				headerSecond.classList.remove('sticky');
				headerSecond.parentNode.removeChild(placeholder);
				isAdded = false;
			}
		});

		window.addEventListener('load', function () {
			if (window.scrollY >= headPosition && !isAdded) {
				headerSecond.classList.add('sticky');
				headerSecond.parentNode.insertBefore(placeholder, headerSecond);
				isAdded = true;
			} else if (window.scrollY < headPosition && isAdded) {
				headerSecond.classList.remove('sticky');
				headerSecond.parentNode.removeChild(placeholder);
				isAdded = false;
			}
		});
	}

	function initToggleNav() {
		const toggle = document.querySelector('.checkbox');
		const nav = document.querySelector('.header__container-nav');
		const headerFirst = document.querySelector('.header__first');
		const headerSecond = document.querySelector('.header__second');
		const body = document.querySelector('body');

		if (toggle) {
			toggle.addEventListener('click', () => {
				toggle.classList.toggle('checked');
				nav.classList.toggle('toggle-active');
				body.classList.toggle('no-scroll');
				if (headerSecond) {
					headerFirst.classList.toggle('fixed');
					headerSecond.classList.toggle('hidden');
				}
			});
		}
	}

	function initVideoButtons() {
		// Кнопки для видео
		const videoBtn = document.querySelector('.video__btn');
		//const videoLink = document.querySelector('.video__src');
		const videoItem = document.querySelector('.video__video-container');

		videoBtn && videoBtn.addEventListener('click', function () {
			const videoId = videoItem.getAttribute('data-video-id');
			videoItem.style.display = 'block';
			videoItem.insertAdjacentHTML(
				'afterbegin',
				'<iframe class="video__file" width="100%" height="100%"\n' +
				'        data-src="https://www.youtube.com/embed/' + videoId + '" frameborder="0"\n' +
				'        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
				'        allowfullscreen></iframe>',
			);
			const videoFile = document.querySelector('.video__file');
			const link = videoFile.dataset.src;
			videoFile.setAttribute('src', link);
		});
	}

	// Кнопка на странице 404
	function init404Button() {
		const errorButton404 = document.querySelector('.js-error-button');
		const errorWindow404 = document.querySelector('.js-error-modal');
		const browserContent = document.querySelector('.js-404-browser-content');
		if (errorButton404 && errorWindow404 && browserContent) {
			errorButton404.addEventListener('click', () => {
				errorWindow404.style.opacity = 0;
				setTimeout(() => {
					errorWindow404.style.opacity = 1;
					increaseHeight();
				}, 1000);
			});
			let browserContentHeight = 5;

			function increaseHeight() {
				browserContentHeight += 5;
				if (browserContentHeight > 89) {
					browserContentHeight = 5;
				}
				browserContent.style.height = browserContentHeight + '%';
			}
		}
	}

	// Часы на странице 404
	function init404Clock() {
		const clock404 = document.querySelector('.js-maximal-sijeko-time');
		if (clock404) {
			function setTime() {
				const now = new Date();
				const min = now.getMinutes();
				//const sec = now.getSeconds();
				clock404.textContent = now.getHours() + ' : ' + (min < 10 ? '0' : '') + min;
			}

			setTime();
			setInterval(setTime, 1000);
		}
	}

	function initHeroScroll() {
		// Скролл картинки на половину
		const heroContainer = document.querySelector('.hero__main');
		const mediaQuery = window.matchMedia('(max-width: 768px)');

		if (mediaQuery.matches && heroContainer) {
			heroContainer.addEventListener('overflow', scroll);
			heroContainer.addEventListener('touchmove', function () {
				clearTimeout(slideImg);
				clearTimeout(returnImg);
			});
			let slideImg = setInterval(function () {
				let newShift = heroContainer.scrollWidth / 3 + 100;
				heroContainer.scrollTo({
					left: newShift,
					behavior: 'smooth',
				});
			}, 5000);

			let returnImg = setInterval(function () {
				let newShift = heroContainer.scrollWidth / 3;
				heroContainer.scrollTo({
					left: newShift,
					behavior: 'smooth',
				});
			}, 5200);
		} else {
			return;
		}

		function scroll() {
			const vertical_shift = heroContainer.scrollWidth / 3;
			heroContainer.scrollTo({
				left: vertical_shift,
			});
		}

		// Запускаем скролл на половину страницы
		scroll();
	}

	function animateDrone(selector) {
		const drone = document.querySelector(selector);
		if (!drone) {
			return;
		}

		const movingPeriod = 11 * 1000;
		const xRadius = 140;
		const yRadius = 25;
		const rotationPeriod = 3 * 1000;
		const rotationAmplitude = 10;
		const timeStart = 1 * new Date();

		function step() {
			const time = 1 * new Date() - timeStart;
			const angle = rotationAmplitude * Math.sin(2 * Math.PI * time / rotationPeriod);
			const x = xRadius * (Math.cos(2 * Math.PI * time / movingPeriod) - 1);
			const y = yRadius * Math.sin(2 * Math.PI * time / movingPeriod);
			drone.setAttribute(
				'transform',
				'translate(' + x + ' ' + y + ') rotate(' + angle + ' 1700 175)'
			);
			window.requestAnimationFrame(step);
		}

		window.requestAnimationFrame(step);
	}

	function animateTrafficLights() {
		const cross = document.querySelector('#Union_53');
		const red = document.querySelector('#Ellipse_58');
		const green = document.querySelector('#Ellipse_58_2');

		if (!cross || !red || !green) {
			return;
		}

		const redColor = 'hsl(0deg 100% 91%)';
		const greenColor = 'hsl(120deg 100% 91%)';
		const backColor = '#FBFBF6';
		const switchDelay = 5 * 1000;

		let isRed = true;

		function step() {
			if (isRed) {
				red.setAttribute('fill', redColor);
				green.setAttribute('fill', backColor);
				cross.style.visibility = 'visible';
			} else {
				red.setAttribute('fill', backColor);
				green.setAttribute('fill', greenColor);
				cross.style.visibility = 'hidden';
			}
			isRed = !isRed;
		}

		step();
		setInterval(step, switchDelay);
	}

	function initBruceBanner() {
		const bannerOff = document.querySelector('#floor2_not_banner');
		const bannerOn = document.querySelector('#floor2_bruce_banner');
		const banner = document.querySelector('#banner');
		if (!bannerOff || !bannerOn || !banner) {
			return;
		}

		if (location.search.match(/[?]demo-banner=1/i)) {
			bannerOff.style.display = 'none';
			bannerOn.style.display = 'block';
		} else {
			bannerOff.style.display = 'block';
			bannerOn.style.display = 'none';
		}

		const href = banner.getAttribute('data-href');
		if (href) {
			banner.addEventListener('click', event => {
				event.preventDefault();
				window.open(href, banner.getAttribute('data-target'));
			});
		}
	}

	function fixHeadTable() {
		const tableHead = document.querySelector('.table__head');
		const placeholder = document.createElement('div');
		const tableFooter = document.querySelector('.table__footer');

		if (!tableHead) {
			return;
		}
		let headParams = tableHead.getBoundingClientRect();

		let tablePosition = tableFooter.offsetTop;
		let el = tableHead;
		let offsetTop = 0;

		while (el !== document.body) {
			offsetTop += el.offsetTop;
			el = el.offsetParent;
		}

		let headPosition = offsetTop - 70;
		placeholder.style.width = headParams.width + 'px';
		placeholder.style.height = headParams.height + 10 + 'px';

		window.addEventListener('scroll', function () {
			if (window.scrollY >= headPosition && window.scrollY <= tablePosition) {
				tableHead.classList.add('sticky-head');
				tableHead.parentNode.insertBefore(placeholder, tableHead);
			} else {
				tableHead.classList.remove('sticky-head');
				placeholder.remove();
			}
		});
	}

	function syncTableScroll() {
		const tableContainer = document.querySelector('.table__desktop');
		const tableHeader = document.querySelector('.table__head');
		if (!tableContainer || !tableHeader) {
			return;
		}
		tableContainer.addEventListener('scroll', () => {
			if (!tableHeader.classList.contains('sticky-head')) {
				// Если заголовок не фиксированный, ничего не делаем
				return;
			}
			// Смещаем заголовок на столько же пикселей,
			// на сколько сместилось тело таблицы при скролле
			tableHeader.style.marginLeft = -tableContainer.scrollLeft + 'px';
		});
	}


}
