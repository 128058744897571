import { createPopper } from '@popperjs/core';

const SHOW_EVENTS = ['mouseenter', 'focus'];
const HIDE_EVENTS = ['mouseleave', 'blur'];

export default function initPopovers(selector: string): void {
	const doc = document;

	doc.querySelectorAll(selector).forEach((popover: HTMLElement) => {
		const selector = popover.getAttribute('data-popover-target');
		if (!selector) {
			return;
		}
		const target: HTMLElement = doc.querySelector(selector);
		if (!target) {
			return;
		}

		const hoverSelector = popover.getAttribute('data-popover-target-hover');
		const targetHover: HTMLElement = hoverSelector ? doc.querySelector(hoverSelector) : null;
		if (targetHover) {
			targetHover.style.opacity = '0';
		}

		const popperInstance = createPopper(target, popover, {
			placement: 'top',
			modifiers: [
				{
					name: 'preventOverflow',
					options: {
						altAxis: true,
						padding: { top: 80 },
					},
				},
			],
		});

		function show(): void {
			popover.setAttribute('data-show', '');
			target.setAttribute('data-active', 'false');
			target.style.opacity = '0';
			if (targetHover) {
				targetHover.style.opacity = '1';
				targetHover.setAttribute('data-active', 'true');
			}
			popperInstance.update();
		}

		function hide(): void {
			popover.removeAttribute('data-show');
			target.setAttribute('data-active', 'true');
			target.style.opacity = '1';
			if (targetHover) {
				targetHover.style.opacity = '0';
				targetHover.setAttribute('data-active', 'false');
			}
		}

		SHOW_EVENTS.forEach(event => target.addEventListener(event, show));
		HIDE_EVENTS.forEach(event => target.addEventListener(event, hide));
		SHOW_EVENTS.forEach(event => popover.addEventListener(event, show));
		HIDE_EVENTS.forEach(event => popover.addEventListener(event, hide));

		if (targetHover) {
			SHOW_EVENTS.forEach(event => targetHover.addEventListener(event, show));
			HIDE_EVENTS.forEach(event => targetHover.addEventListener(event, hide));
		}
	});
}
